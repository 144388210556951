@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @apply bg-white text-black text-left;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.dropdown-menu {
    opacity: 0;
    transition: opacity 0.2s ease-in;
}

.dropdown-menu.show {
    opacity: 1;
}

.dropdown-menu.unshow {
    opacity: 0;
    display: none;
}

.carousel-wrapper {
    position: relative;
}

.carousel-container {
    display: flex;
    flex-wrap: nowrap;
    transition: all 0.5s ease-in-out;
}

.animate-in {
    animation: slide-in 0.5s ease-in-out backwards;
}

@keyframes slide-in {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(0);
    }
}

.accordion {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-out;
}
.accordion.active {
    max-height: 100vh;
    transition: max-height 0.5s ease-in;
}

.spinner {
    animation: spin 1s linear infinite;
    border-radius: 50%;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

.choice {
    padding: 10px 25px 10px 10px;
}

.choice:hover {
    background-color: rgb(239, 239, 239);
    cursor: pointer;
}

.single-value {
    color: #0a8335;
}

.city-choice {
    padding: 10px 25px 10px 10px;
}

.city-choice:hover {
    cursor: pointer;
}

.city-choice:hover .city-choiceOption {
    color: #edf7f1;
    background: #116421;
}

.city-placeholder {
    color: #525260;
}

.selector {
    appearance: none;
    background-image: url('assets/down-chevron-svgrepo-com.svg');
    background-repeat: no-repeat;
    background-position: right 1rem center;
    background-size: 1em;
}

.td-item {
    position: flex;
    transition: transform 0.5s ease-in-out;
}

.animate-in .td-item {
    transition: transform 0.5s ease-in-out;
}

.td-item.next {
    transform: translateX(100%);
}

.td-item.prev {
    transform: translateX(-100%);
}

.td-item.active {
    transform: translateX(0%);
}

.bloc-content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: 100%;
    height: auto;
}

.bloc-content::before {
    content: attr(data-letter);
    left: -10rem;
    position: absolute;
    color: white;
    font-weight: 500;
    font-size: 6rem;
}

.message-content {
    border-radius: 5px;
    background-color: #f4f4f5;
}

.message-content:focus-visible {
    outline: 1px solid #00a253;
}

textarea.text-sm {
    border-radius: 12px;
}

textarea.text-sm:focus-visible {
    outline: none;
}

.realisation-inactive {
    background-color: #fafafa;
    border: #00a253 solid;
}
.realisation-active {
    background-color: #00a253;
    color: #fff;
    font-weight: 600;
}

.prose {
    max-width: none;
    color: black;
}

.isLT {
    background-color: #edf7f1;
    border-color: transparent;
    white-space: pre-wrap;
}

input::placeholder {
    color: #525260;
    opacity: 1;
}

input:focus-within {
    outline: none !important;
    border-radius: 4px 4px 4px 0px;
    border: 2px solid #0a8335 !important;
}

.css-mohuvp-dummyInput-DummyInput {
    height: 0;
}

address {
    font-style: inherit;
}

.h1 {
    @apply font-h1 text-h1m leading-h1m;

    @media (min-width: 640px) {
        @apply text-h1 leading-h1;
    }
}
.h2 {
    @apply font-h2 text-h2m leading-h2;

    @media (min-width: 640px) {
        @apply text-h2;
    }
}
.h3 {
    @apply font-h3 text-h3m leading-h3;

    @media (min-width: 640px) {
        @apply text-h3;
    }
}
.h4 {
    @apply font-h4 text-h4m leading-h4;

    @media (min-width: 640px) {
        @apply text-h4;
    }
}
.paragraph {
    @apply font-paragraph text-paragraph leading-paragraph;
}

.fade-enter {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}

.fade-enter-active {
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
}

.fade-exit {
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
}

.fade-exit-active {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}
.section-style {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 1.5rem;
    background-color: white;
}
.h1-title {
    margin-bottom: 2rem;
    margin-top: 1.5rem;
    font-size: 1.5rem;
    font-weight: 600;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    z-index: 5;
    position: relative;
    @media (min-width: 768px) {
        font-size: 2.25rem;
    }

    @media (min-width: 1024px) {
        font-size: 3rem;
    }
}

.h1-subtext {
    color: rgba(82, 82, 96, var(--tw-text-opacity));
    text-align: left;
    z-index: 5;
    position: relative;
    @media (min-width: 768px) {
        margin-right: 8rem;
    }
}

.title-subtext {
    margin-bottom: 2rem;
    margin-top: 2rem;
    
    @media (min-width: 1024px) {
        max-width: 30vw;
        text-align: left;
    }
}

.img-grid {
    min-height: auto;
    max-height: 450px;
    overflow: hidden;
    padding-left: 5rem;
    position: absolute;
    float: left;
    margin-top: -24rem;
    left: 0;

    @media (min-width: 640px) {
        min-height: 200px;
    }

    @media (min-width: 768px) {
        min-height: 250px;
    }
    @media (max-width: 768px) {
        margin-top: -40rem;
        padding-left: unset;
    }
}
