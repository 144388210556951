.button{
    &:hover{
        cursor: pointer;
    }
    &:disabled {
        cursor: not-allowed;
    }

    &-icon{
        @apply flex items-center gap-1;

        svg{
            @apply fill-current;
        }
    }

    &-primary,
    &-secondary{
        @apply text-button leading-button font-button rounded-full;

        padding: 1rem 2rem; // todo use spacing

        &:active{
            @apply shadow-inset;
        }
    }

    &-primary{
        @apply text-white bg-primary-500;

        &:hover,
        &:active,
        &.force-hover{
            @apply bg-primary-600;
        }
        &:disabled {
            @apply bg-primary-500
        }
    }

    &-secondary{
        @apply text-black border border-black;

        &:hover,
        &:active,
        &.force-hover{
            @apply bg-black/5;
        }
    }

    &-link{
        @apply text-link leading-link font-link;

        &:hover,
        &.force-hover{
            @apply text-primary-500
        }
        &:active{
            @apply text-primary-600
        }
    }
}

.button-active-on-click-secondary:active {
    @apply bg-primary-500 text-white;
}

.button-active-on-click-primary:active {
    @apply bg-secondary-300 text-black;
}
