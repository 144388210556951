@keyframes slideRight {
    0% {
      transform: translateX(0px);
    }
    100% {
      transform: translateX(100px);
    }
  }
  
  .emoji {
    animation: slideRight 1s infinite;
  }
  
  rect {
    fill: url(#myG);
  }
  
  .animate-bottom {
    &.first {
      animation: move-bottom 1s linear -0.3s infinite alternate;
    }
    &.second {
      animation: move-bottom 1s linear infinite alternate;
    }
  }
  
  .animate-top {
    &.first {
      animation: move-top 1s linear -0.3s infinite alternate;
    }
    &.second {
      animation: move-top 1s linear infinite alternate;
    }
  }
  
  @keyframes move-bottom {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(2px, 2px);
    }
  }
  
  @keyframes move-top {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(-2px, -2px);
      fill: red;
    }
  }
  